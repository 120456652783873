import 'angular';

import '../polyfills';

import './api/api.module';
import './assembly-payments/assembly-payments.module.ajs';
import './attachments/attachments.module.ajs';
import './azure-sso/azure-sso.module.ajs';
import './biller/biller.module.ajs';
import './branches/branches.module';
import './business-intelligence/business-intelligence.module';
import './chargify/chargify.module.ajs';
import './core/core.module.ajs';
import './companies/companies.module.ajs';
import './company-lists/company-lists.module.ajs';
import './customer-payments/customer-payments.module.ajs';
import './customer-portal/customer-portal.module.ajs';
import './customers/customers.module.ajs';
import './email-verification/email-verification.module.ajs';
import './flippay/flippay.module.ajs';
import './grids/grids.module';
import './gsf/gsf.module.ajs';
import './imports/imports.module';
import './inspections/inspections.module.ajs';
import './invoices/invoices.module.ajs';
import './messages/messages.module.ajs';
import './modals/modals.module.ajs';
import './myob/myob.module.ajs';
import './neto/neto.module.ajs';
import './partstech/partstech.module.ajs';
import './price-matrix/price-matrix.module';
import './products/products.module.ajs';
import './quickbooks/quickbooks.module.ajs';
import './recaptcha/recaptcha.module.ajs';
import './reports/reports.module';
import './sessions/sessions.module.ajs';
import './shared/shared.module.ajs';
import './stripe/stripe.module.ajs';
import './subscriptions/subscriptions.module.ajs';
import './table/table.module.ajs';
import './templates/template.module';
import './tireconnect/tireconnect.module.ajs';
import './transaction-center/transaction-center.module.ajs';
import './units/units.module.ajs';
import './users/users.module.ajs';
import './vehicles/vehicles.module';
import './vendor-invoices/vendor-invoices.module';
import './vendor-payments/vendor-payments.module';
import './vendors/vendors.module.ajs';
import './workshoppay/workshoppay.module.ajs';
import './xero/xero.module.ajs';

angular.module('app-lite', [
  // Core
  'app-lite.core',

  // Components
  'app-lite.api',
  'app-lite.assembly-payments',
  'app-lite.attachments',
  'app-lite.azure-sso',
  'app-lite.biller',
  'app-lite.branches',
  'app-lite.business-intelligence',
  'app-lite.chargify',
  'app-lite.companies',
  'app-lite.company-lists',
  'app-lite.customer-payments',
  'app-lite.customer-portal',
  'app-lite.customers',
  'app-lite.email-verification',
  'app-lite.flippay',
  'app-lite.grids',
  'app-lite.gsf',
  'app-lite.imports',
  'app-lite.inspections',
  'app-lite.invoices',
  'app-lite.messages',
  'app-lite.modals',
  'app-lite.myob',
  'app-lite.neto',
  'app-lite.partstech',
  'app-lite.price-matrix',
  'app-lite.products',
  'app-lite.quickbooks',
  'app-lite.recaptcha',
  'app-lite.reports',
  'app-lite.sessions',
  'app-lite.shared',
  'app-lite.stripe',
  'app-lite.subscriptions',
  'app-lite.table',
  'app-lite.templates',
  'app-lite.tireconnect',
  'app-lite.transaction-center',
  'app-lite.units',
  'app-lite.users',
  'app-lite.vehicles',
  'app-lite.vendors',
  'app-lite.vendor-invoices',
  'app-lite.vendor-payments',
  'app-lite.workshopPay',
  'app-lite.xero'
]);
