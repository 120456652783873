import 'angular';

import BuySmsHtml from './company.buy-sms.html';

export function CompanyService (
  $q,
  $translate,
  $uibModal,
  confirm,
  COUNTRIES,
  MYOB,
  Quickbooks,
  sessionObjAPI,
  workshopAPI,
  xeroApi
) {
  var Company = function () {
    this.$translate = $translate;

    this.countries  = COUNTRIES;

    if (this.company) {
      this.labels     = this.getLabels();
    }
  };

  Company.prototype.areServiceAdvisersEnabledOnReports = function () {
    const self     = this;
    const settings = sessionObjAPI.base();

    return settings.gold_or_higher && self.company.adviser_report;
  };

  Company.prototype.areTrackingCategoriesAvailable = function () {
    return this.hasIntegration('xero')
      && this.company.company_settings.xero_tracking_categories_status;
  };

  Company.prototype.areTrackingCategoryOptionsAvailable = function () {
    return this.areTrackingCategoriesAvailable()
      && !!this.company.company_accounting_settings.xero_tracking_category_id;
  };

  Company.prototype.buySMS = function () {
    return $uibModal.open({
      controller : 'BuySmsCtrl',
      templateUrl: BuySmsHtml,
      resolve    : {
        cost : () => workshopAPI.get('/sms_logs/current_sms_cost'),
        count: () => this.company.sms_count_left
      }
    }).result;
  };

  Company.prototype.confirmAccountingIntegration = function () {
    return confirm.generic($translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATION_WARNING'), 'warning', {
      cancelText : 'Cancel',
      confirmText: 'Continue',
      size       : 'lg'
    });
  };

  Company.prototype.country = function(country) {
    const self = this;

    if (angular.isArray(country)) {
      for (let i=0; i<country.length; i++) {
        if (self.company.country_code === self.countries[country[i]]) {
          return true;
        }
      }

      return false;
    }

    return self.company.country_code === self.countries[country];
  };

  Company.prototype.getAccountingSettings = function() {
    return workshopAPI.get('/company_accounting_settings');
  };

  Company.prototype.getAccountName = function (code, accounts) {
    let account    = {};

    if (accounts && accounts.length) {
      account = accounts.find(option => option.code === code);

      if (account) {
        return account.description || '';
      }
    }

    return '';
  };

  Company.prototype.getAllAccounts = function () {
    const self = this;

    if (self.hasIntegration('myob')) {
      const myobApi = new MYOB();

      return myobApi.getOptions()
      .then(response => {
        return {
          purchase: response.accounts.expense_accounts.map(account => {
            return {
              code       : account.UID,
              description: account.Name
            };
          }),
          sale: response.accounts.income_accounts.map(account => {
            return {
              code       : account.UID,
              description: account.Name
            };
          })
        };
      });
    }
    else if (self.hasIntegration('quickbooks')) {
      const qboApi = new Quickbooks();

      return qboApi.getAccounts()
      .then(accounts => {
        return {
          purchase: accounts.expense_accounts.map(account => {
            return {
              code       : account.id,
              description: account.name
            };
          }),
          sale: accounts.income_accounts.map(account => {
            return {
              code       : account.id,
              description: account.name
            };
          })
        };
      });
    }
    else if (self.hasIntegration('xero')) {
      return xeroApi.settings()
      .then(response => {
        return {
          purchase: response.chart_of_accounts.expense_accounts,
          sale    : response.chart_of_accounts.revenue_accounts
        };
      });
    }

    return $q.when([]);
  };

  Company.prototype.getCategoryName = function(id, categories) {
    var category = categories.find(option => option.id === id);

    return category ? category.description : '';
  };

  Company.prototype.getDefaultMessages = function () {
    return workshopAPI.get('/company_message_settings');
  };

  Company.prototype.getId = function() {
    const self = this;

    return self.company.id;
  };

  Company.prototype.getLabels = function() {
    const self   = this;
    var   labels = {};

    // Employee title
    if (self.company.company_variable_fields && self.company.company_variable_fields.employee_title_name) {
      labels.employeeTitle = self.company.company_variable_fields.employee_title_name;
    }
    else {
      self.$translate.onReady(() => {
        labels.employeeTitle = self.$translate.instant('EMPLOYEE_SPACE.EMPLOYEE');
      });
    }

    // Business license title
    if (self.country('australia') && self.company.state === 'NSW') {
      labels.stateBusinessLicense = self.$translate.instant('JS_SPACE.LABELS.MVR_LICENSE_NO');
    }
    else if (self.country('australia') && self.company.state === 'WA') {
      labels.stateBusinessLicense = self.$translate.instant('JS_SPACE.LABELS.MRB_LICENSE_NO');
    }

    // Member Number
    if ( self.company.company_variable_fields
      && self.company.company_variable_fields.member_number_name
      && self.usesMemberNumbers()
    ) {
      labels.memberNumberName = self.company.company_variable_fields.member_number_name;
    }
    else {
      self.$translate.onReady(() => {
        labels.memberNumberName = self.$translate.instant('COMPANY_SPACE.SETTINGS.MEMBER_NUMBER');
      });
    }

    // Fleet Code
    if ( self.company.company_variable_fields && self.company.company_variable_fields.fleet_code_name ) {
      labels.fleetCode = self.company.company_variable_fields.fleet_code_name;
    }
    else {
      self.$translate.onReady(() => {
        labels.fleetCode = self.$translate.instant('VEHICLE_SPACE.FLEET_CODE');
      });
    }

    // Rego
    if ( self.company.company_variable_fields && self.company.company_variable_fields.plate_number_name ) {
      labels.rego = self.company.company_variable_fields.plate_number_name;
    }
    else {
      labels.rego = sessionObjAPI.countrySettings().rego_label;
    }

    // VIN
    if ( self.company.company_variable_fields && self.company.company_variable_fields.vin_name ) {
      labels.vin = self.company.company_variable_fields.vin_name;
    }
    else {
      self.$translate.onReady(() => {
        labels.vin = self.$translate.instant('VEHICLE_SPACE.VIN');
      });
    }

    // Vehicle title
    if (self.company.ws_vehicle_title && self.hasAdvancedVehicleFields()) {
      labels.vehicle_name = self.company.ws_vehicle_title;
    }
    else {
      labels.vehicle_name = 'Vehicles';
    }

    return labels;
  };

  Company.prototype.getPaymentAccounts = function () {
    const self = this;

    if (self.hasIntegration('xero')) {
      return xeroApi.settings()
      .then(data => data.chart_of_accounts.payment_accounts)
      .catch(() => []);
    }

    return $q.when([]);
  };

  Company.prototype.getTaxName = function () {
    if (this.country('usa')) {
      return 'TAX';
    }
    if (this.country('unitedKingdom')) {
      return 'VAT';
    }

    return 'GST';
  };

  Company.prototype.getUtcOffset = function () {
    return this.company.utc_offset || 0;
  };

  Company.prototype.hasActiveAccount = function () {
    const self = this;

    return self.hasIntegration('myob')
    || self.hasIntegration('quickbooks')
    || self.hasIntegration('xero');
  };

  Company.prototype.hasPurchaseAccounts = function () {
    const self = this;

    return self.company.company_accounting_settings.use_product_for_purchase_account
    && ( self.hasIntegration('myob') || self.hasIntegration('xero') );
  };

  Company.prototype.hasAdvancedVehicleFields = function () {
    const self = this;

    return self.company.advanced_vehicle_fields;
  };

  Company.prototype.hasDealership = function () {
    const self = this;

    return self.company.has_dealership;
  };

  Company.prototype.hasMultiTax = function () {
    const self = this;

    return !!self.company.use_multi_tax;
  };

  Company.prototype.hasWorkshop = function () {
    const self = this;

    return self.company.has_workshop;
  };

    /**
   * Checks if a certain integration is active. Right now only
   * supports the following:
   *   assembly-payments
   *   autoinspect
   *   burson
   *   business-intelligence
   *   capricorn
   *   carfax
   *   celero
   *   customer-portal
   *   dvla
   *   mitchell1
   *   motor
   *   myob
   *   parts-authority
   *   partstech
   *   quickbooks
   *   stripe
   *   tireconnect
   *   tiremetrix
   *   uk-postcode
   *   vehicle-registrations
   *   vehicle-visuals
   *   video-minutes
   *   vvgarage
   *   xero
   *
   * all should be included eventually and can be added as we
   * change over the checks.
   */
  Company.prototype.hasIntegration = function (integration) {
    const self    = this;
    const session = sessionObjAPI.base();

    switch (integration) {
      case 'ams-rewards':
        return self.company.ams_rewards_settings.has_ams_rewards;
      case 'ashdown':
        return self.isIntegrationAvailable(integration)
          && self.company.has_ashdown_ingram;
      case 'assembly-payments':
        return self.isIntegrationAvailable(integration) && self.company.pos_terminal_settings.has_assembly_payments;
      case 'autoinspect':
        return self.company.has_autoinspect;
      case 'burson':
        return self.company.burson_ezyparts_settings.has_burson_ezyparts;
      case 'business-intelligence':
        return session.gold_or_higher &&
          session.user_settings.business_intelligence_user;
      case 'capricorn':
        return self.company.capricorn_settings.has_capricorn;
      case 'carfax':
        return self.company.has_carfax
          && self.country([ 'canada', 'usa' ])
          && !self.isDealer();
      case 'castrol':
        return self.company.castrol_sap_settings.has_castrol_sap;
      case 'celero':
        return self.isIntegrationAvailable(integration)
          && self.company.tnp_settings.has_tnp;
      case 'customer-portal':
        return self.isIntegrationAvailable(integration) &&
          self.company.company_settings.has_customer_portal;
      case 'dvla':
        return self.company.has_dvla_lookup &&
          self.country([ 'ireland', 'northernIreland', 'unitedKingdom' ]);
      case 'flippay':
        return self.isIntegrationAvailable('flippay')
          && self.company.has_flippay;
      case 'gsf':
        return self.isIntegrationAvailable('gsf')
          && self.company.gsf_car_parts_settings
          && self.company.gsf_car_parts_settings.has_gsf_car_parts;
      case 'hsy':
        return self.isIntegrationAvailable(integration)
          && self.company.hsy_settings.has_hsy;
      case 'mitchell1':
        return self.isIntegrationAvailable(integration)
          && self.company.mitchell1_settings.has_mitchell1;
      case 'motor':
        return self.isIntegrationAvailable(integration)
          && self.company.motor_settings.has_motor_labour;
      case 'myob':
        return self.company.has_myob;
      case 'neto':
        return self.isIntegrationAvailable(integration) &&
          self.company.neto_settings.has_neto;
      case 'oscar':
        return self.isIntegrationAvailable(integration) &&
          self.company.oscar_settings.has_oscar;
      case 'parts-authority':
        return self.isIntegrationAvailable(integration)
          && self.company.parts_authority_settings.has_parts_authority;
      case 'partstech':
        return self.isIntegrationAvailable(integration) &&
          self.company.partstech_settings.has_partstech;
      case 'partstech-labor':
        return self.hasIntegration('partstech')
          && self.company.partstech_settings.has_partstech_labour_times;
      case 'prolink':
        return self.isIntegrationAvailable(integration)
          && self.company.has_prolink;
      case 'quickbooks':
        return self.company.has_quickbooks;
      case 'registration-lookup':
        return self.country('australia')
          && self.company.registration_lookup_settings.has_registration_lookup
          && !self.isDealer();
      case 'repco':
        return self.isIntegrationAvailable(integration)
          && self.company.has_repco;
      case 'stripe':
        return self.isIntegrationAvailable('stripe')
          && self.company.stripe_settings.has_stripe;
      case 'supercheap':
        return self.isIntegrationAvailable('supercheap')
          && self.company.has_supercheap;
      case 'tireconnect':
        return self.isIntegrationAvailable(integration) &&
          self.company.tireconnect_settings.has_tireconnect;
      case 'tiremetrix':
        return self.company.tiremetrix_settings.has_tiremetrix_tire_registration ||
        self.company.tiremetrix_settings.has_tiremetrix_tpms;
      case 'uk-postcode':
        return self.company.has_uk_postcode &&
          self.country([ 'northernIreland', 'unitedKingdom' ]);
      case 'vehicle-registrations':
        return self.hasIntegration('registration-lookup')
          || (self.country('newZealand') && self.company.has_checka);
      case 'vehicle-visuals':
        return self.company.has_vehicle_visuals;
      case 'video-minutes':
        return self.company.has_video_minutes;
      case 'vvgarage':
        return self.company.vv_garage_settings.has_vv_garage;
      case 'xero':
        return self.company.has_xero;
      default:
        return false;
    }
  };

  Company.prototype.hasPOS = function () {
    const self = this;

    return self.hasIntegration('assembly-payments') ||
      self.hasIntegration('celero');
  };

  Company.prototype.hasReservedStock = function () {
    const self = this;

    return self.company.reserved_stock;
  };

  Company.prototype.hasSecurityRights = function(right) {
    const self = this;

    return self.company.company_security_settings && self.company.company_security_settings[right];
  };

  Company.prototype.isAccountingSetup = function() {
    const self = this;

    return self.company.accounting_is_setup;
  };

  Company.prototype.isActive = function ( company = this.company ) {
    return company.subscription_status === 'active';
  };

  Company.prototype.isAzureLoginRequired = function ( company = this.company ) {
    return company.sso_settings.azure_required_login;
  };

  Company.prototype.isBosch = function() {
    const brand = this.company.branding_group;

    return brand === 'bosch'
        || brand === 'autocrew'
        || brand === 'bosch_gen';
  };

  Company.prototype.isBranchDealer = function () {
    const self = this;

    return self.company.dealer_multibranch
        || self.company.dealer_multibranch_version;
  };

  Company.prototype.isBranchOwner = function() {
    const self = this;

    return self.isMultiBranch() && self.company.is_branch_owner;
  };

  Company.prototype.isBranchStore = function() {
    return this.isDealerOrBranch()
        && !this.company.is_branch_owner;
  };

  Company.prototype.isBrand = function (brand, company = this.company) {
    return company.branding_group === brand;
  };

  Company.prototype.isBrandPuppet = function (brand) {
    const self    = this;
    const session = sessionObjAPI.base();

    return session.puppet_session
        && self.isBrand(brand)
        && !sessionObjAPI.dealer_superuser();
  };

  Company.prototype.isBranded = function() {
    const self = this;

    return self.isBosch()
        || self.isBrand('nrma')
        || self.isBrand('tread')
        || self.isBrand('shell');
  };

  Company.prototype.isDealerOrBranch = function() {
    return !!this.company.branch_id;
  };

  Company.prototype.isDealer = function ( company = this.company ) {
    return !!company.dealer;
  };

  Company.prototype.isDealerMultibranchStore = function () {
    return workshopAPI.get('/company/check_dealer_multibranch_status');
  };

  Company.prototype.isDealerOrBranch = function() {
    return !!this.company.branch_id;
  };

  Company.prototype.isFlatRateEnabled = function () {
    const self = this;

    return self.company.company_settings.enable_flat_rate;
  };

  Company.prototype.isIntegrationAvailable = function (integration) {
    const self    = this;
    const session = sessionObjAPI.base();

    switch (integration) {
      case 'ams-rewards':
        return self.country('australia')
          && session.gold_or_higher;
      case 'ashdown':
        return self.country([ 'australia', 'newZealand' ]);
      case 'assembly-payments':
        return self.country('australia');
      case 'celero':
        return self.country(['usa', 'canada']);
      case 'customer-portal':
        return session.gold_or_higher;
      case 'flippay':
        return self.country('australia')
        /**
         * This check is added because we only want flippay to be available
         * to the user if they do not have stripe enabled
         * REFERENCE WOR-4041
         */
          && !self.company.stripe_settings.has_stripe;
      case 'gsf':
        return self.country('unitedKingdom');
      case 'hsy':
        return self.country([ 'australia', 'newZealand' ])
          && session.silver_or_higher;
      case 'mitchell1':
        return self.country(['usa', 'canada']);
      case 'motor':
        return self.country([ 'canada', 'usa' ]);
      case 'neto':
        return false;
      case 'oscar':
        return self.country([ 'australia', 'newZealand' ])
          && session.silver_or_higher;
      case 'parts-authority':
        return self.country(['usa', 'canada']);
      case 'partstech':
        return self.country([ 'canada', 'usa' ]);
      case 'prolink':
        return self.country([ 'australia', 'newZealand' ]);
      case 'repco':
        return self.country([ 'australia', 'newZealand' ]);
      case 'stripe':
        return self.country([
          'australia',
          'austria',
          'belgium',
          'brazil',
          'bulgaria',
          'canada',
          'cyprus',
          'czech',
          'denmark',
          'estonia',
          'france',
          'germany',
          'greece',
          'hongKong',
          'hungary',
          'india',
          'ireland',
          'italy',
          'japan',
          'latvia',
          'lithuania',
          'luxembourg',
          'malaysia',
          'malta',
          'mexico',
          'netherlands',
          'newZealand',
          'northernIreland',
          'norway',
          'poland',
          'portugal',
          'romania',
          'singapore',
          'slovakia',
          'slovenia',
          'spain',
          'sweden',
          'switzerland',
          'uae',
          'unitedKingdom',
          'usa'
        ])
        /**
         * This check is added because we only want stripe to be available
         * to the user if they do not have flippay enabled
         * REFERENCE WOR-4041
         */
        && !self.company.has_flippay;
      case 'supercheap':
        return self.isBrand('autocrew')
          && self.country([ 'australia', 'newZealand' ]);
        case 'till':
        return self.isIntegrationAvailable('assembly-payments');
      case 'tireconnect':
        return self.country([ 'canada', 'usa' ]);
      case 'westpac':
        const flags = session.flags;

        return self.isIntegrationAvailable('assembly-payments')
          && flags.has_westpac_terminal
          && !flags.has_till_payments_terminal;
      default:
        return false;
    }
  };

  Company.prototype.isLinkedToBranch = function () {
    const self = this;

    return self.isMultiBranch() && self.company.linked_to_branch;
  };

  Company.prototype.isMultiBranch = function() {
    const self = this;

    return self.company.is_multibranch;
  };

  Company.prototype.isNRMAUser = function (security_type) {
    const self = this;

    return self.hasSecurityRights(security_type) && !self.isBrandPuppet('nrma') && self.isBrand('nrma');
  };

  Company.prototype.isSharedAccounting = function () {
    const self = this;

    return self.isMultiBranch() && self.company.shares_accounting;
  };

  Company.prototype.isState = function ( state ) {
    return this.company.state === state;
  };

  Company.prototype.isSubscriptionPlan = function ( type = 'bronze', matchExactly = true ) {
    const session = sessionObjAPI.base();

    switch (type) {
      case 'bronze':
        return !session.silver_or_higher;
      case 'gold':
        return matchExactly
          ? ( session.gold_or_higher && !session.platinum_or_higher )
          : session.gold_or_higher;
      case 'platinum':
        return session.platinum_or_higher;
      case 'silver':
        return matchExactly
          ? ( session.silver_or_higher && !session.gold_or_higher && !session.platinum_or_higher )
          : session.silver_or_higher;
    }
  };

  Company.prototype.isTwoFactorAuthEnabled = function ( companySettings = this.company.company_settings ) {
    return companySettings.enable_two_factor_auth;
  };

  Company.prototype.isTwoFactorAuthEnforced = function ( companySettings = this.company.company_settings ) {
    return companySettings.enforce_two_factor_auth;
  };

  Company.prototype.isTrialing = function ( company = this.company ) {
    return company.subscription_status === 'trailing';
  };

  Company.prototype.language = function(language) {
    const self      = this;
    const languages = {
      uk : 'en-uk',
      usa: 'en-us'
    };

    return self.company.language === languages[language];
  };

  Company.prototype.usesMemberNumbers = function () {
    const self = this;

    return self.company.company_settings.use_member_numbers;
  };

  return Company;
};
